const { convertModelToFormData } = require("@dvsproj/ipat-core/apiUtils");

const checkResponse = (res: Response) => {
  if (!res.ok) {
    if ([401, 403].indexOf(res.status) >= 0) {
      throw new Error("login");
    } else {
      console.error("Response failed: " + res.status);
      throw new Error(`${res.status}: ${res.statusText}`);
    }
  }
};

const fetchToShop = async (
  url: string,
  method?: string,
  params?: any,
  config?: any
) => {
  let res: Response;
  if (method === "POST") {
    res = await postFormData(url, params, config);
  } else {
    res = await get(url, params, config);
  }

  checkResponse(res);

  return res;
};

/**
 * Wrapper for sending one line GET requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const get = async (url: string, params?: any, config: any = {}) => {
  const separator = url.search(/\?/) ? "&" : "?";

  const response = await fetch(
    url +
      (params && Object.keys(params).length > 0
        ? separator + new window.URLSearchParams(params)
        : ""),
    {
      ...config,
      credentials: config.credentials || "same-origin",
    }
  );

  checkResponse(response);

  return response;
};

/**
 * Wrapper for sending one line POST requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const post = async (url: string, body?: any, defaultConfig: any = {}) => {
  const config = {
    ...defaultConfig,
    credentials: defaultConfig.credentials || "same-origin",
    method: defaultConfig.method || "POST",
    headers: {
      ...(defaultConfig.headers || {}),
    },
    body: body != null ? body : undefined,
  };

  const response = await fetch(url + "", config);

  checkResponse(response);

  return response;
};

/**
 * Wrapper for sending one line POST requests with JSON.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const postJSON = (url: string, body?: any, defaultConfig: any = {}) => {
  const config = {
    ...defaultConfig,
    headers: {
      ...(defaultConfig.headers || {}),
      "Content-Type": "application/json",
    },
  };
  return post(url, JSON.stringify(body), config);
};

/**
 * Wrapper for sending one line POST requests with FormData.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const postFormData = async (
  url: string,
  body?: any,
  defaultConfig: any = {}
): Promise<Response> => {
  const config = {
    ...defaultConfig,
    headers: {
      ...(defaultConfig.headers || {}),
    },
  };

  return post(url, convertModelToFormData(body), config);
};

/**
 * Wrapper for sending one line DELETE requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} params
 * @param {*} config
 * @throws throws error with statusText if response.ok returned false
 */
const deleteMethod = async (url: string, params?: any, config: any = {}) => {
  const separator = url.search(/\?/) ? "&" : "?";

  const response = await fetch(
    url +
      (params && Object.keys(params).length > 0
        ? separator + new window.URLSearchParams(params)
        : ""),
    {
      ...config,
      method: "DELETE",
      credentials: config.credentials || "same-origin",
    }
  );

  checkResponse(response);

  return response;
};

const CORS_CONFIG = {
  credentials: "include",
  mode: "cors",
};

export type ApiConfig = {
  userinfoURL: string;
  planByIdURL: string;
  savePlanURL: string;
  removePlanURL: string;
  pricesURL: string;
  cartGeneratorURL: string;
  createPlanURL: string;
  planCheckedURL: string;
  planToCheckURL: string;
  wishListGeneratorURL: string;
  session: string;
};

export type CartRequestItem = {
  articleNo: string;
  quantity: number;
};

declare global {
  interface Window {
    APP_CONFIG: any;
  }
}

const URL_CONSTANTS = {
  $SHOP_URL: window?.APP_CONFIG?.REACT_APP_SHOP_URL,
  $CALC_BACKEND_URL: window?.APP_CONFIG?.REACT_APP_CALC_BACKEND_URL,
  $SMART_GARDEN_URL: window?.APP_CONFIG?.REACT_APP_SMART_GARDEN_URL,
};

export function urlDecorator(url: string) {
  return Object.keys(URL_CONSTANTS).reduce(
    (r, key) => r.replace(key, URL_CONSTANTS[key]),
    url
  );
}

export function apiFactory({
  userinfoURL,
  planByIdURL,
  savePlanURL,
  removePlanURL,
  pricesURL,
  cartGeneratorURL,
  createPlanURL,
  planCheckedURL,
  planToCheckURL,
  wishListGeneratorURL,
  session,
}: ApiConfig) {
  let csrf_token: string;
  return {
    getUserInfo: async () => {
      try {
        const res = await fetchToShop(userinfoURL);

        const { status, error_msg, response } = await res.json();

        if (status !== "ok") {
          throw new Error(
            error_msg
              ? error_msg
              : "An error occurred while getting user information"
          );
        }

        csrf_token = response.csrf_token;

        return response;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    createPlan: async (name: string, plan: any) => {
      const imageBase64 =
        plan.background && plan.background.src ? plan.background.src : null;

      const imageType = imageBase64?.match(/^data:([/\w]+);/);

      const res = await fetchToShop(createPlanURL, "POST", {
        name,
        image: imageBase64,
        image_type: imageType != null ? imageType[1] : null,
        csrf_token,
      });

      const { status, error_msg, response } = await res.json();

      if (status !== "ok") {
        throw new Error(
          error_msg ? error_msg : "An error occurred while creating plan"
        );
      }

      return response;
    },
    getPlanById: async (id?: string | null) => {
      if (id == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await fetchToShop(planByIdURL, "GET", {
        ident: id,
        csrf_token,
      });
      const { status, error_msg, response } = await res.json();

      if (status !== "ok") {
        throw new Error(
          error_msg ? error_msg : "An error occurred while getting plan"
        );
      }

      const {
        data: dataStr,
        image,
        image_type,
        ident,
        name,
        status: planStatus,
      } = response;

      const data = JSON.parse(dataStr);
      if (planStatus != null) data.status = planStatus;

      return {
        name,
        data,
        image,
        image_type,
        ident,
      };
    },
    removePlanById: async (id: string) => {
      if (id == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await fetchToShop(removePlanURL, "POST", {
        csrf_token: csrf_token,
        id: id,
      });
      const { status, error_msg, response } = await res.json();
      if (status === "ok" && response.status) {
        return true;
      } else {
        throw new Error(error_msg || "Error by remove plan");
      }
    },
    savePlan: async (
      id: string,
      name: string,
      planJSON: any,
      saveStatus: string
    ) => {
      if (planJSON == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await fetchToShop(savePlanURL, "POST", {
        ident: id,
        name: name,
        status: saveStatus,
        planningTime: planJSON.planningTime,
        data: JSON.stringify(planJSON),
        csrf_token: csrf_token,
      });
      const { status, error_msg, response } = await res.json();
      if (status === "ok" && response.status) {
        return true;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return false;
      }
    },
    planChecked: async (id: string) => {
      const res = await fetchToShop(planCheckedURL, "POST", {
        ident: id,
        csrf_token: csrf_token,
      });

      const { status, error_msg, response } = await res.json();
      if (status === "ok" && response.status) {
        return true;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return false;
      }
    },
    planToCheck: async (id: string) => {
      const res = await fetchToShop(planToCheckURL, "POST", {
        ident: id,
        csrf_token: csrf_token,
      });
      const { status, error_msg, response } = await res.json();
      if (status === "ok" && response.status) {
        return true;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return false;
      }
    },
    getWishListUrl: async (
      id: string,
      articlesWithQuantity: CartRequestItem[],
      locale: string | null
    ) => {
      const res = await fetchToShop(wishListGeneratorURL, "POST", {
        ident: id,
        articles: articlesWithQuantity,
        csrf_token: csrf_token,
      });
      const { status, error_msg, response } = await res.json();

      if (status !== "ok") {
        throw new Error(error_msg ? error_msg : "An error");
      }
      //REMOVME when mr.Hagel fix wishlist function in shop side
      //temporary fix for IPAT-649
      let redirect = await response.redirect;
      try {
        let pathByLocale = locale?.startsWith("de")
          ? "wunschliste"
          : "wishlist";
        pathByLocale = locale?.startsWith("fr")
          ? "fr/liste-de-souhaits"
          : pathByLocale;
        const url = new URL(redirect);
        url.pathname = `/${pathByLocale}`;
        redirect = url.toString();
      } catch (e) {
        console.error(`Redirect url parse failed`, e);
      }
      return redirect;
    },
    getPricesByArticleNumbers: async (articleNumbers: string[]) => {
      if (articleNumbers != null && articleNumbers.length > 0) {
        const res = await fetchToShop(pricesURL, "POST", {
          articles: articleNumbers,
          csrf_token: csrf_token,
        });
        const { status, error_msg, response } = await res.json();

        if (status !== "ok") {
          throw new Error(
            error_msg ? error_msg : "An error occurred while getting prices"
          );
        }

        return response.articles;
      }

      return [];
    },
    getCartUrl: async (id: string, articlesWithQuantity: CartRequestItem[]) => {
      const res = await fetchToShop(cartGeneratorURL, "POST", {
        ident: id,
        articles: articlesWithQuantity,
        csrf_token: csrf_token,
      });
      const { status, error_msg, response } = await res.json();

      if (status !== "ok") {
        throw new Error(
          error_msg ? error_msg : "An error occurred while getting prices"
        );
      }
      return await response.redirect;
    },
    duplicatePlan: async (planId: string) => {
      const res = await postJSON(
        `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/duplicatePlan?session=${session}`,
        {
          plan_id: planId,
          csrf_token: csrf_token,
        },
        CORS_CONFIG
      );

      const result = await res.json();

      if (result.error) {
        throw new Error(
          result.error || "An error occured during plan duplication"
        );
      }

      return result;
    },
  };
}

export const fetchSettingsJSON = async () => {
  const response = await fetch(
    `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/settings.json`
  );
  if (!response.ok) throw new Error("Failed to load settings");
  return response.json();
};

export const calcApiFactory = () => {
  return {
    calculateSprinklers: async (
      planId: string,
      plan: any,
      recalcSprinklers: any
    ) => {
      if (plan == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        console.debug("Calculate sprinklers");
        const request = await postJSON(
          `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/sprinklers`,
          { plan, recalcSprinklers },
          CORS_CONFIG
        );
        return request.json();
      } catch (err) {
        console.error("Error calculating sprinklers");
        throw err;
      }
    },
    calculatePipeline: async (planId: string, params: any) => {
      if (params == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        console.debug("Calculate pipeline");
        const request = await postJSON(
          `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/pipeline`,
          params,
          CORS_CONFIG
        );
        return request.json();
      } catch (err) {
        console.error("Error calculating pipeline");
        throw err;
      }
    },
    generateImage: async (
      planId: string | undefined,
      plan: any,
      layouts: any,
      textsVisibility: any
    ) => {
      console.debug("Generate image");
      if (plan == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await postJSON(
        `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/generatePNG`,
        {
          ident: planId,
          plan,
          layouts,
          textsVisibility,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    generatePdf: async (
      planId: string | undefined,
      plan: any,
      locale: string,
      action: string
    ) => {
      console.debug("Generate pdf");
      if (plan == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await postJSON(
        `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/generatePDF`,
        {
          ident: planId,
          plan,
          url: window.location.href,
          locale,
          action,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    trenchingWays: async (
      planId: string,
      plan: any,
      ways: any,
      trenchedPaths: any
    ) => {
      console.debug("trenching ways..");

      if (plan == null || ways == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        const request = await postJSON(
          `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/trench`,
          { plan, ways, trenchedPaths },
          CORS_CONFIG
        );
        return request.json();
      } catch (err) {
        console.error("Error calculating pipeline");
        throw err;
      }
    },
    saveFeedback: async (
      planId: string,
      email: string,
      rate: any,
      category: string,
      description: string
    ) => {
      const res = await postJSON(
        `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/feedback`,
        {
          ident: planId,
          email,
          rate,
          category,
          description,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    requestRainBirdProducts: async (planId: string, email: string) => {
      const res = await postJSON(
        `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/rain-bird-products`,
        {
          ident: planId,
          email,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    requestInstaller: async (
      planId: string,
      email: string,
      zip: string,
      city: string,
      country: string
    ) => {
      const res = await postJSON(
        `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/installer`,
        {
          ident: planId,
          email,
          zip,
          city,
          country,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    savePlan: async (
      planId: string,
      name: string,
      planJSON: any,
      saveStatus: string,
      imageFile: any
    ) => {
      if (planJSON == null) {
        throw new Error("Invalid parameter value");
      }

      const form = new FormData();
      form.append("ident", planId);
      form.append("name", name);
      form.append("status", saveStatus);
      form.append("planningTime", planJSON.planningTime);
      form.append("data", JSON.stringify(planJSON));
      if (imageFile != null) {
        form.append("image", imageFile);
      }

      try {
        const request = await post(
          `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/savePlan`,
          form,
          CORS_CONFIG
        );

        return request.json();
      } catch (err) {
        console.error("Error save plan");
        throw err;
      }
    },
    saveStats: async (planId: string, statsJSON: any) => {
      if (statsJSON == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        await postJSON(
          `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${planId}/stats`,
          {
            ident: planId,
            data: statsJSON,
          },
          CORS_CONFIG
        );
      } catch (err) {
        console.error("Error save stats");
        throw err;
      }
    },
    getAssessments: async (planId: string) => {
      if (planId == null) return;

      const url = `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/admin/${planId}/assessment`;
      const res = await get(url, null, CORS_CONFIG);
      const response = await res.json();

      const { assessment, annotations } = response;
      if (assessment == null && annotations == null) return null;

      return { assessment, annotations };
    },
    saveAssessments: async (planId: string, assessmentPayload: any) => {
      const url = `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/admin/${planId}/assessment`;

      const res = await postJSON(url, assessmentPayload, CORS_CONFIG);
      const result = await res.json();

      return result;
    },
    removeAssessments: async (planId: string) => {
      const url = `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/admin/${planId}/assessment`;

      await deleteMethod(url, null, CORS_CONFIG);

      return true;
    },
    sendMarketingStatistics: async (data: any) => {
      try {
        await postJSON(
          `${window.APP_CONFIG.REACT_APP_CALC_BACKEND_URL}/${data.planId}/marketingStatistics`,
          data,
          CORS_CONFIG
        );
      } catch (err) {
        console.error("Error save stats");
        throw err;
      }
    },
  };
};
